<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard >
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-info text-light">
                            <div class="card-body d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Today </div>
                                    <div class="text-value">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-secondary text-light">
                            <div class="card-body d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Today </div>
                                    <div class="text-value">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-warning text-light">
                            <div class="card-body d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Today </div>
                                    <div class="text-value">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-danger text-light">
                            <div class="card-body d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Today </div>
                                    <div class="text-value">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-info">
                            <div class="card-body text-light d-flex  align-items-center">
                                <div>
                                    <div class=" font-weight-bold"> Total Article </div>
                                    <div class="text-value ">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-secondary">
                            <div class="card-body text-light d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Total Quizs </div>
                                    <div class="text-value">{{ parseFloat(formData.previous_day_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-warning">
                            <div class="card-body text-light d-flex align-items-center">
                                <div>
                                    <div class="font-weight-bold"> Article Views </div>
                                    <div class="text-value ">{{ parseFloat(formData.last_seven_day_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card bg-gradient-danger">
                            <div class="card-body d-flex align-items-center text-light">
                                <div>
                                    <div class="font-weight-bold"> Quiz Views </div>
                                    <div class="text-value text-gradient-primary">{{ parseFloat(formData.this_month_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" md="8">
        <topTen></topTen>
      </CCol>
      <CCol sm="12" md="4">
        <CCard >
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-12 col-lg-12">
                        <table class="table table-sm table table-bordered">
                            <tr>
                                <td widtd="60%">Last 7 Day Paid</td>
                                <td class="text-right"> {{ parseFloat(formData.last_seven_day_paid).toFixed(2) }} $</td>
                            </tr>
                            <tr>
                                <td>Last 7 Day Pending</td>
                                <td class="text-right"> {{ parseFloat(formData.last_seven_day_pending).toFixed(2) }} $</td>
                            </tr>
                            <tr>
                                <td>This Month</td>
                                <td class="text-right"> {{ parseFloat(formData.this_month_paid).toFixed(2) }} $</td>
                            </tr>
                            <tr>
                                <td>Total Paid</td>
                                <td class="text-right"> {{ parseFloat(formData.total_paid).toFixed(2) }} $</td>
                            </tr>
                            <tr>
                                <td>Total Pending</td>
                                <td class="text-right"> {{ parseFloat(formData.total_pending).toFixed(2) }} $</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import topTen from '../componests/dashboard/top-ten.vue'
import RestApi, { baseUrl } from '../config/api_config'
export default {
  name: 'Dashboard',
  components: {
    topTen
  },
  data () {
    return {
      selected: 'Month',
      formData: {}
    }
  },
  created() {
      this.loadData()
  },
  methods: {
        loadData () {
            const params = {}
            this.$store.dispatch('mutedLoad', { loading: true})
            RestApi.getData(baseUrl, 'api/dashboard/data', params).then(response => {
                if (response.success) {
                    this.formData = response.data
                }
                this.$store.dispatch('mutedLoad', { loading: false })
            })
        },
  }
}
</script>
<style scoped>
.text-value {
    font-size: 18px !important;
}
</style>
